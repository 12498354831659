import React, { useState } from 'react';
import { ResponsiveLine } from '@nivo/line';
import { ResponsiveBar } from '@nivo/bar';
import { EmotionData, getEmotionData } from '../services/mockDataService';
import { FaFrown, FaMeh, FaSmile } from 'react-icons/fa';
import { CustomBarComponent } from './CustomBarComponent';
import { theme } from '../constants';


// Function to determine the path to the emoticon based on the emotion level
export const getEmoticonIcon = (level: number, size: number = 100): JSX.Element => {
  const style = { fontSize: `${size}px`, color: level === 0 ? '#cc0000' : level === 1 ? '#cccc00' : '#00cc66', opacity: 0.7 };

  switch (level) {
    case 0:
      return <FaFrown style={style} />;
    case 1:
      return <FaMeh style={style} />;
    case 2:
      return <FaSmile style={style} />;
    default:
      return <FaMeh style={style} />;
  }
};

const getEmoticonIconFromState = (state: String, size: number = 100): JSX.Element => {

  switch (state) {
    case 'Happy':
      return <FaSmile style={{fontSize: `${size}px`,color: '#00cc66'}} />;
    case 'Neutral':
      return <FaMeh style={{fontSize: `${size}px`,color: '#cccc00'}} />;
    case 'Sad':
      return  <FaFrown style={{fontSize: `${size}px`,color: '#cc0000'}} />;
    default:
      return <FaMeh style={{fontSize: `${size}px`,color: '#cccc00'}} />;
  }
}

interface AverageEmotionProps {
  time: string;
  data: EmotionData[];
}

// Custom layer to show average emotion and icon
const AverageEmotion: React.FC<AverageEmotionProps> = ({ data, time }) => {
  console.log(time)
  const d = data.filter(p => p.time === time)[0]
  const emoticon = getEmoticonIcon(Math.floor(d.level));
  const color = d.level < 1 ? '#cc0000' : d.level < 2 ? '#cccc00' : '#00cc66';
  console.log(color)
  return (
    <div className='flex flex-col flex-1 items-center justify-center'>
      <div className=' text-xl'>{emoticon}</div>

      <div style={{ marginTop: '10px', fontSize: '3rem', color }}>
        {d.level + '/3'}
        
      </div>
      <div style={{ marginTop: '5px', fontSize: '1rem', color:'gray' }}>
      <p>Emotion rating</p>
        
      </div>
      
    </div>
  );
};

const EmotionLineChart: React.FC = () => {
  const data: EmotionData[] = getEmotionData();
  const [time, setTime] = useState('10:30');

  // Format data for Nivo
  const nivoData = [{
    id: 'emotion',
    data: data.map(d => ({ x: d.time, y: d.level }))
  }];

  // Calculate distribution for bar chart

  const barData = (time: string) => {
    const d = data.filter(p => p.time === time)[0]

    return [
      { emotion: 'Happy', count: d.happy },
      { emotion: 'Neutral', count: d.neutral },
      { emotion: 'Sad', count: d.sad }
    ];


  }
  const barColors = (bar: any) => {
    switch (bar.indexValue) {
      case 'Happy':
        return '#00cc66';
      case 'Neutral':
        return '#cccc00';
      case 'Sad':
        return '#cc0000';
      default:
        return '#000000';
    }
  };



  const onBarClick = (bar: any) => {
    const dataPoint = data[bar.index];
    if (dataPoint) {
      setTime(dataPoint.time);
    } else {
      console.warn('Data point not found for index:', bar.indexValue);
    }
  };


  return (
    <div className='h-[600px] w-full flex flex-col'>
      <div className='flex flex-row flex-1 justify-around mt-2 items-center'>
        <div className='flex h-[300px] items-center'>
          <AverageEmotion data={data} time={time} />
        </div>
        <div className='w-[800px] h-[300px] flex flex-col py-2 px-4 justify-start items-center border-solid border-2 rounded-xl border-gray-300 '> 
        <p className='text-center text-gray-500 mt-4'>Breakdown (Percentage %)</p>
        <ResponsiveBar
          data={barData(time)}
          keys={['count']}
          indexBy="emotion"
          axisBottom={null}
          axisLeft={{
            renderTick : ({x,y,value})=>{
              return <g transform={`translate(${x-70},${y-25})`}>{getEmoticonIconFromState(value, 50)}</g> 
            },
            ticksPosition:'before',
            tickPadding: 20
          }}
          enableGridY={false}
          labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                animate={true}
          barComponent={(bar) =>
            <CustomBarComponent
              bar={bar.bar}
              handleMouseEnter={() => { }}
              handleMouseLeave={() => { }}
              hoveredBarIndex={null}
              onClick={() => { }}
            />}
          margin={{ top: 5, right: 20, bottom: 20, left: 70 }}
          padding={0.5}
          layout="horizontal"
          colors={barColors}
          theme={theme}
        /></div>

      </div>
      <div className='h-[300px]'>
        <ResponsiveBar
          data={data}
          keys={['level']} // Replace 'level' with the key that corresponds to your bar values
          indexBy="time" // Replace 'time' with the key that represents your X-axis labels
          margin={{ top: 20, right: 20, bottom: 70, left: 80 }}
          padding={0.75}
          layout="vertical"
          axisLeft={{
            tickValues: 3,
            legend: 'Rating',
                    legendPosition: 'middle',
                    legendOffset: -60
          }}
          enableGridX={false}
          labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                animate={true}
          gridYValues={[0, 1, 2,3]} // Modify this array for the desired grid lines
          colors={(bar) => (bar.value ? (bar.value < 1 ? '#cc0000' : bar.value < 2 ? '#cccc00' : '#00cc66') : 'gray')}
          axisBottom={{
            legend: 'Time',
                    legendPosition: 'middle',
                    legendOffset: 50
          }}
          onClick={onBarClick}
          barComponent={(bar) =>
            <CustomBarComponent
              bar={bar.bar}
              handleMouseEnter={() => { }}
              handleMouseLeave={() => { }}
              hoveredBarIndex={data.findIndex(d=> d.time===time)}
              onClick={((_) => onBarClick(bar.bar))}
            />}
          

          theme={theme}
        />
      </div>
    </div>

  );
};

export default EmotionLineChart;
