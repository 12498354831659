import React, { useState, MouseEvent } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { CustomBarComponent } from './CustomBarComponent';
import { BasicTooltip, useTooltip } from '@nivo/tooltip';
import { theme } from '../constants';
import { ResponsivePie } from '@nivo/pie';

// Tableau 10 color palette
const tableauColors = [
  '#4e79a7', '#f28e2b', '#e15759', '#76b7b2',
  '#59a14f', '#edc948', '#b07aa1', '#ff9da7',
  '#9c755f', '#bab0ab'
];

// Mock data
const data = [
  { category: '< 20', value: 12,id:1 },
  { category: '21-40', value: 35,id:2 },
  { category: '41-60', value: 41,id:3 },
  { category: '61+', value: 11,id:4 }
];


const VisitorAgeChart = () =>{
  const [hoveredBarIndex, setHoveredBarIndex] = useState<number | null>(null);
    const { showTooltipFromEvent, hideTooltip } = useTooltip();

    const handleMouseEnter = (bar: any, e: MouseEvent<SVGGElement>) => {
        setHoveredBarIndex(bar.index);
        showTooltipFromEvent(
            <BasicTooltip id={`Time: ${bar.indexValue}`} value={`${bar.data.walkIns} people`} />,
            e
        );
    };

    const handleMouseLeave = () => {
        setHoveredBarIndex(null);
        hideTooltip();
    };

   return (

  <div style={{ height: 200, width: '100%' }}>
    <ResponsivePie
      data={data}
      colors={({ id }) => {
        console.log(id)
        const index = data.findIndex(d => d.id === id);
        return tableauColors[index % tableauColors.length];
      }}
      margin={{ top: 20, right: 20, bottom: 20, left: 120 }}
      innerRadius={0.5}
      padAngle={0.7}
      arcLabelsTextColor={'#ffffff'}
      cornerRadius={3}
      activeOuterRadiusOffset={8}
      borderWidth={1}
      borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
      legends={[
        {
          data: data.map((d,index) => {
            return {
              color: tableauColors[index],
              label: d.category +' : '+ d.value +' %',
              id: d.category
            }
          }),
          anchor: 'left',
          direction: 'column',
          justify: false,
          translateX: -100,
          translateY: 0,
          itemsSpacing: 10,
          itemWidth: 150,
          itemHeight: 30,
          itemTextColor: '#999',
          symbolSize: 20,
          effects: [
            {
              on: 'hover',
              style: {
                itemTextColor: '#000'
              }
            }
          ],
        }
      ]}
      theme={theme}
    //   tooltip={({ id, value }) => (
    //     <div className="flex flex-col items-center text-[12px] shadow-lg bg-gray-800 text-white rounded-xl px-4 py-2">
    //                 <div className="absolute -bottom-2 left-1/2 transform -translate-x-1/2 w-4 h-4 bg-gray-800 rotate-45"></div>

    //   <div className="flex items-center justify-between w-full">
    //     <p className="font-semibold mr-2">{id}</p>
    //     <p className="font-bold">{String(value)}</p>
    //   </div>
    // </div>
    //   )}
    />
  </div>
)};

export default VisitorAgeChart;
