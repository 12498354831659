
import React, { useState, MouseEvent } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { useTooltip, BasicTooltip } from '@nivo/tooltip';
import { CustomBarComponent } from './CustomBarComponent';
import { theme } from '../constants';

const WalkInBarGraph: React.FC = () => {
    const [hoveredBarIndex, setHoveredBarIndex] = useState<number | null>(null);
    const { showTooltipFromEvent, hideTooltip } = useTooltip();

    const handleMouseEnter = (bar: any, e: MouseEvent<SVGGElement>) => {
        setHoveredBarIndex(bar.index);
        showTooltipFromEvent(
            <BasicTooltip id={`Time: ${bar.indexValue}`} value={`${bar.data.walkIns} people`} />,
            e
        );
    };

    const handleMouseLeave = () => {
        setHoveredBarIndex(null);
        hideTooltip();
    };

    const data = [
        { scale: "Minute", time: "10:00", walkIns: 102 },
        { scale: "Minute", time: "10:10", walkIns: 98 },
        { scale: "Minute", time: "10:20", walkIns: 82 },
        { scale: "Minute", time: "10:30", walkIns: 78 },
  
      { scale: "Day", time: "Monday", walkIns: 150 },
      { scale: "Day", time: "Tuesday", walkIns: 180 },
      { scale: "Day", time: "Wednesday", walkIns: 165 },
      { scale: "Day", time: "Thursday", walkIns: 170 },
      { scale: "Day", time: "Friday", walkIns: 200 },
      { scale: "Day", time: "Saturday", walkIns: 220 },
  
      { scale: "Month", time: "January", walkIns: 1200 },
      { scale: "Month", time: "February", walkIns: 1100 },
      { scale: "Month", time: "March", walkIns: 1150 },
      { scale: "Month", time: "April", walkIns: 1300 },
      { scale: "Month", time: "May", walkIns: 1400 },
      { scale: "Month", time: "June", walkIns: 1350 },
    ];

    const filteredData = data.filter(d => d.scale === 'Minute');

    return (
        <div style={{ height: '500px', width: '100%' }}>
            <ResponsiveBar
                data={filteredData}
                keys={['walkIns']}
                indexBy="time"
                margin={{ top: 20, right: 30, bottom: 70, left: 80 }}
                padding={0.7}
                groupMode="grouped"
                axisTop={null}
                axisRight={null}
                gridYValues={3}
                axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Time',
                    legendPosition: 'middle',
                    legendOffset: 50
                }}
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Walk-Ins',
                    legendPosition: 'middle',
                    legendOffset: -60
                }}
                colors={(bar) => bar.index === filteredData.length - 1 ? '#ff6363' : '#4e79a7'}
                
                borderRadius={10}
                enableLabel={false}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                animate={true}
                barComponent={(bar) =>
                    <CustomBarComponent
                        bar={bar.bar}
                        handleMouseEnter={handleMouseEnter}
                        handleMouseLeave={handleMouseLeave}
                        hoveredBarIndex={hoveredBarIndex}
                        onClick={(_)=>{}}
                    />
                }
                theme={theme}
            />
        </div>
    );
};

export default WalkInBarGraph;
