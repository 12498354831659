import React, { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaCheck, FaCheckCircle, FaCircle, FaPhone, FaWhatsapp } from 'react-icons/fa';
import { MdMoreVert, MdDelete } from 'react-icons/md';
import { MdPerson, MdWork, MdPhone, MdMessage } from 'react-icons/md';
import {  MdVpnKey } from 'react-icons/md';


interface SimpleModalProps {
    onConfirm: () => void;
    onCancel: () => void;
  }
  
  const SimpleModal: React.FC<SimpleModalProps> = ({ onConfirm, onCancel }) => {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
        <div className="bg-white p-4 rounded-lg shadow-lg">
          <h2 className="font-semibold text-xl">Confirm Action</h2>
          <p className="my-4">Are you sure you want to set this profile as primary contact for all notifications?</p>
          <div className="flex justify-end gap-4">
            <button className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600" onClick={onCancel}>Cancel</button>
            <button className="bg-brand-blue-light text-white px-4 py-2 rounded hover:bg-brand-blue-dark" onClick={onConfirm}>Confirm</button>
          </div>
        </div>
      </div>
    );
  };

interface MobileNumber {
  id: number;
  name: string,
  designation: string,
  mobile_number: string;
  is_primary: boolean;
  is_verified: boolean;
}

const Alerts: React.FC = () => {
    const [mobileNumbers, setMobileNumbers] = useState<MobileNumber[]>([]);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [selectedMobileId, setSelectedMobileId] = useState<string | null>(null);
    const [step, setStep] = useState<number>(1);
    const [mobileNumber, setMobileNumber] = useState<string>('');
    const [name, setName] = useState<string>('');
    const [designation, setDesignation] = useState<string>('');
    const [otp, setOtp] = useState<string>('');
    const token = localStorage.getItem('token');
  
    useEffect(() => {
      fetchMobileNumbers();
    }, []);
  
    const fetchMobileNumbers = () => {
        setMobileNumbers( [{
            id: 1,
            name: "Ram Prasad",
            designation: "Security Head",
            mobile_number: " +91 8186857565",
            is_primary: true,
            is_verified: true
          },
          {
            id: 2,
            name: "Krishna",
            designation: "Block Manager",
            mobile_number: "+91 9876543210",
            is_primary: false,
            is_verified: true
          },
          {
            id: 3,
            name: "Sanket Kumar",
            designation: "Security Block-B",
            mobile_number: "+91 9556781234",
            is_primary: false,
            is_verified: false
          },
          {
            id: 4,
            name: "Abhinav",
            designation: "Operations Manager",
            mobile_number: "+91 9223334444",
            is_primary: false,
            is_verified: true
          }
        ])
    };
  
    const handleSetPrimary = (number: string) => {
      const mobile = mobileNumbers.find(m => m.mobile_number === number);
      if (mobile && mobile.is_verified) {
        setSelectedMobileId(mobile.mobile_number);
        setShowModal(true);
      } else {
        toast.error('Verification incomplete. Cannot set as primary.');
      }
    };

    const handleMobileDelete = (id: number) => {
        // fetch(`https://api.neurawatch.ai/mobile/${id}`, {
        //   method: 'DELETE',
        //   headers: { 
        //   'Authorization': `Bearer ${token}`
        //    },
        // })
        // .then(response => {
        //   if (response.ok) {
        //     setShowModal(false);
        //     toast.success('Mobile deleted sucessfully.');
        //     fetchMobileNumbers();
        //   } else {
        //     throw new Error('Failed to set as primary');
        //   }
        // })
        // .catch(error => {
        //   toast.error('Error: ' + error.message);
        // });
    }
  
    const confirmPrimary = () => {
    //   if (selectedMobileId) {
    //     const toastId = toast.info(`Setting ${selectedMobileId} as Primary endpoint`, {
    //         autoClose: false, // Prevent the toast from closing automatically
    //       });
    //     fetch(`https://api.neurawatch.ai/mobile/setPrimary`, {
    //       method: 'POST',
    //       headers: { 'Content-Type': 'application/json',
    //       'Authorization': `Bearer ${token}`           },
    //       body: JSON.stringify({ mobile_number: selectedMobileId })
    //     })
    //     .then(response => {
    //       if (response.ok) {
    //         setShowModal(false);
    //         toast.update(toastId, {
    //             render: `Mobile number ${selectedMobileId} set as Primary Endpoint.`,
    //             type: 'success',
    //             autoClose: 5000 // Close after 5000ms
    //           });
    //         fetchMobileNumbers();
    //       } else {
    //         throw new Error('Failed to set as primary');
    //       }
    //     })
    //     .catch(error => {
    //     //   toast.error('Error: ' + error.message);
    //       toast.update(toastId, {
    //         render: 'Error: ' + error.message,
    //         type: 'error',
    //         autoClose: 5000 // Close after 5000ms
    //       });
    //     });
    //   }
    };
  
    const handleNext = () => {
        // const toastId = toast.info('Sending OTP', {
        //     autoClose: false, // Prevent the toast from closing automatically
        //   });
        // fetch('https://api.neurawatch.ai/mobile/register', {
        //   method: 'POST',
        //   headers: {
        //     'Content-Type': 'application/json',
        //     'Authorization': `Bearer ${token}`          },
        //   body: JSON.stringify({ mobile_number: '+91' + mobileNumber, name: name, designation: designation })
        // })
        // .then(response => {
        //   if (response.ok) {
        //     return response.json(); // Assuming the server sends some data we might need
        //   } else {
        //     throw new Error('Failed to register mobile number');
        //   }
        // })
        // .then(data => {
        //     toast.update(toastId, {
        //         render: 'OTP sent successfully.',
        //         type: 'success',
        //         autoClose: 5000 // Close after 5000ms
        //       });
        //   setStep(2); // Proceed to OTP verification step
        // })
        // .catch(error => {
        //     toast.update(toastId, {
        //         render: 'Error: ' + error.message,
        //         type: 'error',
        //         autoClose: 5000 // Close after 5000ms
        //       });
        // });
      };
  
      const handleVerify = () => {
        // console.log(mobileNumber)
        // const toastId = toast.info('Verifying OTP', {
        //     autoClose: false, // Prevent the toast from closing automatically
        //   });
        // fetch('https://api.neurawatch.ai/mobile/confirmOTP', {
        //   method: 'POST',
        //   headers: {
        //     'Content-Type': 'application/json',
        //     'Authorization': `Bearer ${token}`
        //   },
        //   body: JSON.stringify({ mobile_number: '+91'+mobileNumber, otp: otp })
        // })
        // .then(response => {
        //   if (response.ok) {
        //     return response.json(); // If the API provides data on success, you might want to use it
        //   } else {
        //     throw new Error('Failed to confirm OTP');
        //   }
        // })
        // .then(data => {
        //     toast.update(toastId, {
        //         render: 'Mobile number verified successfully.',
        //         type: 'success',
        //         autoClose: 5000 // Close after 5000ms
        //       });
        //   setMobileNumber(''); // Reset mobile number
        //   setOtp(''); // Reset OTP
        //   setDesignation('')
        //   setName('')
        //   setStep(1); // Optionally reset to the first step or navigate elsewhere
        //   fetchMobileNumbers(); // Refresh mobile numbers to reflect the changes
        // })
        // .catch(error => {
        //     toast.update(toastId, {
        //         render: 'Error: ' + error.message,
        //         type: 'error',
        //         autoClose: 5000 // Close after 5000ms
        //       });
        //   setMobileNumber(''); // Reset mobile number
        //   setDesignation('')
        //   setName('')
        //   setOtp('');
        // });
      };
  
    return (
      <div className='flex flex-col gap-2 p-4'>
        <ToastContainer position='bottom-right'/>
        <h2 className="font-semibold text-xl ml-5 text-gray-600">Configured Endpoints for Alerts</h2>
        <div className='flex flex-row flex-wrap gap-4 mx-5 p-2 items-center justify-center'>
          { mobileNumbers.length >0 ?
           mobileNumbers.map((mobile) => <MobileCard mobile={mobile} handleSetPrimary={handleSetPrimary} handleDelete={handleMobileDelete} />)
        : <LoadingCard />}
        </div>
        {showModal && (
          <SimpleModal onConfirm={confirmPrimary} onCancel={() => setShowModal(false)} />
        )}
        <h2 className="font-semibold text-xl ml-5 mt-10 text-gray-600">Add new Endpoints</h2>
        <div className='flex flex-col items-center p-4 bg-[#F2F7FF]'>
        <ProgressHeader step={step} />
          {step === 1 && (
            <Step1
                        mobileNumber={mobileNumber}
                        designation={designation}
                        setMobileNumber={setMobileNumber}
                        onNext={handleNext} name={name} setName={setName} setDesignation={setDesignation}           />
          )}
          {step === 2 && (
            <OTPInput
              otp={otp}
              setOtp={setOtp}
              onVerify={handleVerify}
            />
          )}
        </div>
      </div>
    );
  };
  
  export default Alerts;

  interface OTPProps {
    otp: string;
    setOtp: (value: string) => void;
    onVerify: () => void;
  }
  
  const OTPInput: React.FC<OTPProps> = ({ otp, setOtp, onVerify }) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        console.log(e.target.value)
        setOtp(e.target.value);
    }
    const [timeLeft, setTimeLeft] = useState<number>(30);
    const [canRetry, setCanRetry] = useState<boolean>(false);
  
    useEffect(() => {
      // Countdown effect
      if (timeLeft > 0) {
        const timerId = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
        return () => clearTimeout(timerId);
      } else {
        setCanRetry(true);
      }
    }, [timeLeft]);
  
    const handleRetry = () => {
      // Reset timer and disable retry until timer expires again
      setTimeLeft(30);
      setCanRetry(false);
      console.log("Retry logic here");
      // Implement retry logic, like resending OTP request
    };
  
    return (
      <div className="flex flex-col p-4 w-96 justify-center items-center">
        <label htmlFor="otp" className="mb-2 text-lg font-semibold text-gray-600">Enter OTP</label>
        <span className="text-sm text-gray-500 mb-2">You should receive the message on WhatsApp shortly.</span>
        <input
          id="otp"
          type="text"
          value={otp}
          onChange={handleChange}
          className="flex-1 p-2 border rounded-full w-96"
          placeholder="OTP"
        />
        <div className="flex items-center justify-around space-x-4 mt-4 w-48">
          <button
            onClick={handleRetry}
            className={`px-4 py-2 text-white text-sm rounded-full ${canRetry ? 'bg-brand-blue-dark' : 'bg-gray-400 cursor-not-allowed'}`}
            disabled={!canRetry}
          >
            Resend OTP
          </button>
          {!canRetry && <span className="text-gray-500 p-5 b-2">in {timeLeft}s</span>}
        </div>
        <button
          onClick={onVerify}
          className="mt-5 p-2 bg-brand-blue-light text-white rounded-full w-72"
        >
          Verify
        </button>
        
      </div>
    );
  };
  
  interface Step1Props {
  mobileNumber: string;
  name: string;
  designation: string;
  setMobileNumber: (value: string) => void;
  setName: (value: string) => void;
  setDesignation: (value: string) => void;
  onNext: () => void;
}
  
  const Step1: React.FC<Step1Props> = ({ mobileNumber, name, designation, setMobileNumber, setName, setDesignation, onNext }) => {
    return (
      <div className="flex flex-col p-4 w-2/3 justify-center items-center">
    <label htmlFor="mobile-number" className="mb-2 text-lg font-semibold text-gray-600">General Details</label>

        <div className="flex items-center w-96 mb-5">
          <MdPerson className="text-xl text-brand-blue-light mr-2"/>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="flex-1 p-2 border rounded-full"
            placeholder="Name"
          />
        </div>
        <div className="flex items-center w-96 mb-5">
          <MdWork className="text-xl text-brand-blue-light mr-2"/>
          <input
            type="text"
            value={designation}
            onChange={(e) => setDesignation(e.target.value)}
            className="flex-1 p-2 border rounded-full"
            placeholder="Designation"
          />
        </div>
        <label htmlFor="mobile-number" className="my-2 text-lg font-semibold text-gray-600">Enter Mobile Number</label>
        <div className="flex border rounded-full w-96">
          <span className="bg-brand-blue-light text-white p-2 rounded-l-full">+91</span>
          <input
            id="mobile-number"
            type="text"
            value={mobileNumber}
            onChange={(e) => setMobileNumber(e.target.value)}
            className="flex-1 p-2 rounded-r-full"
            placeholder="Mobile Number"
          />
        </div>
        <div className="text-sm text-gray-600 flex items-center mt-4 ">
          <FaPhone className="text-brand-blue-light mr-2 text-xl"/>
          <FaWhatsapp className="text-brand-blue-light mr-2 text-2xl"/>
          <span>This number will be used for receiving WhatsApp messages and phone calls. OTP will be sent on whatsapp to verify this number.</span>
        </div>
        <button
          onClick={onNext}
          className="mt-10 p-2 w-64 bg-brand-blue-light text-white rounded-full"
        >
          Next
        </button>
      </div>
    );
  };

  interface ProgressHeaderProps {
    step: number;
  }
  
  const ProgressHeader: React.FC<ProgressHeaderProps> = ({ step }) => {
    return (
      <div className="flex items-center justify-between mb-5 w-2/5">
        {/* Step 1 */}
        <div className="flex flex-col items-center">
          <div className={`relative flex items-center justify-center h-10 w-10 rounded-full text-lg font-bold text-white ${step >= 1 ? 'bg-brand-blue-light' : 'bg-gray-300'}`}>
            <span> <MdPhone className="inline-block" /></span>
          </div>
          <span className="text-sm mt-2">Mobile Number</span>
        </div>
        {/* Line between steps */}
        <div className={`flex-auto border-t-4 border-r-4 ${step > 1 ? 'border-brand-blue-light' : 'border-gray-300'}`} style={{ height: '2px' }}></div>
        {/* Step 2 */}
        <div className="flex flex-col items-center">
          <div className={`relative flex items-center justify-center h-10 w-10 rounded-full text-lg font-bold text-white ${step === 2 ? 'bg-brand-blue-light' : 'bg-gray-300'}`}>
            <span> <MdVpnKey className="inline-block" /></span>
          </div>
          <span className="text-sm mt-2">OTP Verification</span>
        </div>
      </div>
    );
  };



  interface MobileCardProps {
    mobile: MobileNumber;
    handleSetPrimary: (id: string) => void;
    handleDelete: (id: number) => void;
  }
  
  const MobileCard: React.FC<MobileCardProps> = ({ mobile, handleSetPrimary, handleDelete }) => {
    return (
      <div key={mobile.id} className={`relative p-4 w-96 md:w-72 rounded-2xl shadow-md cursor-pointer border ${mobile.is_primary ? 'bg-[#EFFAF7] text-[#20C08D]' : 'bg-[#F2F7FF] text-gray-500'}`}
           onClick={() => !mobile.is_primary && handleSetPrimary(mobile.mobile_number)}>
        <div className='absolute top-2 right-2'>
          <div className="relative group">
            <MdMoreVert className="text-xl text-gray-800" />
            <div className="absolute bottom-5 right-2  w-32 h-10 hidden group-hover:flex items-center bg-white shadow-md rounded px-2 py-1 -mt-8" onClick={() => handleDelete(mobile.id)}>
              <MdDelete className="text-red-500 mr-2" />
              <span className="text-sm text-gray-700">Delete</span>
            </div>
          </div>
        </div>
        <div className='text-2xl font-semibold mb-2'>{mobile.name}</div>
        <div className='text-sm mb-3'>{mobile.designation}</div>
        <div className='text-xl font-semibold mb-3'>{mobile.mobile_number}</div>
        
        {mobile.is_primary && (
          <span className='inline-block bg-[#20C08D] text-xs text-white mt-3 mr-3 px-2 py-1 rounded-full'>Primary</span>
        )}
        {mobile.is_verified ? (
          <span className='inline-block bg-[#20C08D] text-xs text-white mt-3 px-2 py-1 rounded-full'>Verified</span>
        ) : <span className='inline-block bg-red-400 text-xs text-white mt-3 px-2 py-1 rounded-full'>Verification Pending</span>}
      </div>
    );
  };
  
  const LoadingCard = () => {
    return (
      <div className="animate-pulse flex space-x-4">
        <div className="rounded-full bg-gray-300 h-12 w-12"></div>
        <div className="flex-1 space-y-6 py-1">
          <div className="h-2 bg-brand-blue-light rounded"></div>
          <div className="space-y-3">
            <div className="grid grid-cols-3 gap-4">
              <div className="h-2 bg-gray-300 rounded col-span-2"></div>
              <div className="h-2 bg-gray-300 rounded col-span-1"></div>
            </div>
            <div className="h-2 bg-gray-300 rounded"></div>
          </div>
        </div>
      </div>
    );
  };
  