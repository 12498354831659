import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { theme } from '../constants';

const data = [
  // { hour: '08:00', Reception: 10, Cataract: 15, Retina: 5, Pharmacy: 20, Refraction: 10 },
  // { hour: '09:00', Reception: 12, Cataract: 18, Retina: 6, Pharmacy: 22, Refraction: 8 },
  { hour: '10:00', Reception: 6, Cataract: 10, Retina: 55, Pharmacy: 4, Refraction: 25 },
  { hour: '10:30', Reception: 4, Cataract: 11, Retina: 56, Pharmacy: 5, Refraction: 24 },
  // { hour: '12:00', Reception: 18, Cataract: 25, Retina: 11, Pharmacy: 28, Refraction: 5 },
  // { hour: '13:00', Reception: 20, Cataract: 27, Retina: 13, Pharmacy: 30, Refraction: 6 },
  // { hour: '14:00', Reception: 22, Cataract: 30, Retina: 15, Pharmacy: 32, Refraction: 7 },
  // { hour: '15:00', Reception: 24, Cataract: 32, Retina: 17, Pharmacy: 34, Refraction: 8 },
  // { hour: '16:00', Reception: 26, Cataract: 35, Retina: 19, Pharmacy: 36, Refraction: 9 },
  // { hour: '17:00', Reception: 28, Cataract: 37, Retina: 21, Pharmacy: 38, Refraction: 10 },
];

const keys = ['Reception','Cataract','Retina','Pharmacy','Refraction'];
const tableauColors = [
  '#4E79A7', '#E15759', '#76B7B2', '#59A14F',
  '#EDC948', '#B07AA1', '#FF9DA7', '#9C755F', '#BAB0AC'
];

const WaitTimeLineChart: React.FC = () => {
  return (
    <div className="h-[400px] w-full">
      <ResponsiveBar
        data={data}
        keys={keys}
        indexBy="hour"
        margin={{ top: 20, right: 130, bottom: 50, left: 60 }}
        padding={0.3}
        layout="vertical"
        colors={({ id }) => tableauColors[keys.indexOf(id as string)]}
        borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        axisTop={null}
        axisRight={null}
        enableLabel
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'Time',
          legendPosition: 'middle',
          legendOffset: 32,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'Time Spent (Percentage %)',
          legendPosition: 'middle',
          legendOffset: -40,
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        animate={true}
        theme={theme}
        legends={[
          {
            dataFrom: 'keys',
            anchor: 'bottom-right',
            direction: 'column',
            justify: false,
            translateX: 120,
            translateY: 0,
            itemsSpacing: 2,
            itemWidth: 100,
            itemHeight: 20,
            itemDirection: 'left-to-right',
            itemOpacity: 0.85,
            symbolSize: 20,
          },
        ]}
      />
    </div>
  );
};

export default WaitTimeLineChart;
