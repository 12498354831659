import React from 'react';
import { FaWind, FaTrash, FaMask } from 'react-icons/fa';

const departmentsHygiene = [
    { department: 'Reception', dust: 'Good', mask:'Good', waste:'Good' },
    { department: 'Refraction', dust: 'Good', mask:'Good', waste:'Good'},
    { department: 'Cataract', dust: 'Good', mask:'Good', waste:'Good' },
    { department: 'Retina', dust: 'Good', mask:'Good', waste:'Good'},
    { department: 'Pharmacy', dust: 'Good', mask:'Good', waste:'Good' },
  
  
  ];
  

interface HygieneAnalysisProps {
    overallStatus: string;
    dustStatus: string;
    wasteStatus: string;
    maskStatus: string;
    onAnalyzeClick: () => void;
}

const HygieneAnalysis: React.FC<HygieneAnalysisProps> = ({
    overallStatus,
    dustStatus,
    wasteStatus,
    maskStatus,
    onAnalyzeClick,
}) => {
    return (
        <div className='flex flex-col items-center'>
            <div className="bg-white p-6 rounded-xl w-96  my-4 shrink-0">

                {/* Overall Hygiene */}
                <div className="flex justify-center  items-center mb-6">
                    <h3 className="text-xl font-semibold text-gray-600 mr-4 ">Overall Hygiene</h3>
                    <p className={`text-xl ${overallStatus === 'Good' ? 'text-green-600' : 'text-red-600'}`}>
                        {overallStatus}
                    </p>
                </div>

                {/* Detailed Status */}
                {/* <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    <div className="flex items-center border p-4 rounded-lg">
                        <FaWind className="text-2xl text-gray-500 mr-2" />
                        <div>
                            <h4 className="text-lg font-semibold text-gray-600">Dust</h4>
                            <p className={`text-base ${dustStatus === 'Good' ? 'text-green-600' : 'text-red-600'}`}>
                                {dustStatus}
                            </p>
                        </div>
                    </div>
                    <div className="flex items-center border p-4 rounded-lg">
                        <FaTrash className="text-2xl text-gray-500 mr-2" />
                        <div>
                            <h4 className="text-lg font-semibold text-gray-600">Waste</h4>
                            <p className={`text-base ${wasteStatus === 'Good' ? 'text-green-600' : 'text-red-600'}`}>
                                {wasteStatus}
                            </p>
                        </div>
                    </div>
                    <div className="flex items-center border p-4 rounded-lg">
                        <FaMask className="text-2xl text-gray-500 mr-2" />
                        <div>
                            <h4 className="text-lg font-semibold text-gray-600">Others</h4>
                            <p className={`text-base ${maskStatus === 'Good' ? 'text-green-600' : 'text-red-600'}`}>
                                {maskStatus}
                            </p>
                        </div>
                    </div>
                </div> */}

                {/* Run Analysis Button */}
                <div className="mt-6 flex flex-col justify-center items-center">
                    <button
                        onClick={onAnalyzeClick}
                        className="bg-brand-blue-light text-white py-2 px-4 rounded-full w-64 hover:bg-blue-700"
                    >
                        Run Analysis Now
                    </button>
                    <div className='text-[0.7rem] font-normal mt-2 text-center'> This analysis uses feed from live data from all the cameras
                        and then performs hygiene analysis on Dust, Waste and Others</div>
                </div>
            </div>
            <div className='flex flex-row gap-5 flex-wrap justify-center'>
               { departmentsHygiene.map((data, index) => (
                <div className='flex flex-col  justify-center align-center border-2 shadow-sm border-gray-200 p-2 rounded-lg '>
                    <div className='flex flex-row justify-between mb-3 items-center'>
                   <h3 className='text-gray-600 text-lg  '>{data.department}</h3>
                   <div className='border-2 border-brand-blue-light p-2 rounded-full'>
                        <button className='text-brand-blue-light text-sm font-normal'>View Images</button>
                        </div>
                   </div>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-2">
                    <div className="flex items-center border p-4 rounded-lg cursor-pointer">
                        <FaWind className="text-2xl text-gray-500 mr-2" />
                        <div>
                            <h4 className="text-lg font-semibold text-gray-600">Dust</h4>
                            <p className={`text-base ${data.dust === 'Good' ? 'text-green-600' : 'text-red-600'}`}>
                                {data.dust}
                            </p>
                        </div>
                    </div>
                    <div className="flex items-center border p-4 rounded-lg cursor-pointer">
                        <FaTrash className="text-2xl text-gray-500 mr-2" />
                        <div>
                            <h4 className="text-lg font-semibold text-gray-600">Waste</h4>
                            <p className={`text-base ${data.waste === 'Good' ? 'text-green-600' : 'text-red-600'}`}>
                                {data.waste}
                            </p>
                        </div>
                    </div>
                    <div className="flex items-center border p-4 rounded-lg cursor-pointer">
                        <FaMask className="text-2xl text-gray-500 mr-2" />
                        <div>
                            <h4 className="text-lg font-semibold text-gray-600">Others</h4>
                            <p className={`text-base ${data.mask === 'Good' ? 'text-green-600' : 'text-red-600'}`}>
                                {data.mask}
                            </p>
                        </div>
                    </div>
                </div>
                </div>
               )
                )}

                

            </div>
        </div>
    );
};

export default HygieneAnalysis;
