// pages/Analytics.tsx

import React, { useState } from 'react';
import EmergencyCodesTab from '../components/EmergencyCodesTab';
import GeneralTab from '../components/GeneralTab';
import PandemicControlTab from '../components/PandemicControlTab';

type Tab = 'general' | 'emergency' | 'pandemic';

const Analytics: React.FC = () => {
    const [activeTab, setActiveTab] = useState<Tab>('general');
    const [contentStyle, setContentStyle] = useState<React.CSSProperties>({});

    const renderTabContent = () => {
        switch (activeTab) {
            case 'general':
                return <GeneralTab />;
            case 'emergency':
                return <EmergencyCodesTab />;
            case 'pandemic':
                return <PandemicControlTab />;
            default:
                return null;
        }
    };


    const handleTabClick = (tab: Tab) => {
        // Set styles to initial transition state
        setContentStyle({
            opacity: 0,
            transform: 'translateX(-10px)',
            transition: 'none'
        });

        // After a small timeout, update the styles to trigger the transition
        setTimeout(() => {
            setContentStyle({
                opacity: 1,
                transform: 'translateX(0)',
                transition: 'opacity 300ms, transform 300ms'
            });
        }, 10);

        setActiveTab(tab);
    };

    return (
        <div className="bg-slate-50 text-pretty font-semibold">
            {/* <div className="mb-4 pt-4 border-gray-200">
                <ul className="flex cursor-pointer text-lg font-bold text-center text-gray-400">
                    <li 
                        className={`ml-10 mr-10 pb-2 hover:text-blue-500 hover:border-blue-300 ${activeTab === 'general' ? 'border-b-2 border-brand-blue-light text-brand-blue-light' : ''}`} 
                        onClick={() => handleTabClick('general')}
                    >
                        Operations
                    </li>
                    <li 
                        className={`mr-10 pb-2 hover:text-blue-500 hover:border-blue-300 ${activeTab === 'emergency' ? 'border-b-2 border-brand-blue-light text-brand-blue-light' : ''}`} 
                        onClick={() => handleTabClick('emergency')}
                    >
                        Emergency Codes
                    </li>
                    <li 
                        className={`mr-10 pb-2 hover:text-blue-500 hover:border-blue-300 ${activeTab === 'pandemic' ? 'border-b-2 border-brand-blue-light text-brand-blue-light' : ''}`} 
                        onClick={() => handleTabClick('pandemic')}
                    >
                        Pandemic Control
                    </li>
                </ul>
            </div> */}
            <div className=" p-4" style={contentStyle}>
            {/* {renderTabContent()} */}
            <GeneralTab />
            <EmergencyCodesTab />
            <PandemicControlTab />
            </div>
        </div>
    );
};

export default Analytics;
