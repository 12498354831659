import React, { useState } from 'react';
import WalkInBarGraph from './WalkInBarGraph';
import Card from './Card';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import VisitorAgeChart from './VisitorAgeChart';
import EmotionLineChart from './EmotionLineChart';
import GenderPieChart from './GenderPieChart';
import WaitTimeLineChart from './WaitTimeLineChart';
import SankeyDiagram from './SankeyDiagram';
import CloggingAnalysisTreemap from './CloggingTreeMap';
import DepartmentWaffleCharts from './DepartmentWaffleCharts';
import DepartmentWaitTime from './DepartmentWaitTime';
import HygieneAnalysis from './HygieneAnalysis';

const GeneralTab: React.FC = () => {
    const [selectedOption, setSelectedOption] = useState('H'); // Default to 'week'
    const [startDate, setStartDate] = useState<Date | null>(new Date());

    // Function to handle the radio option change
    const handleOptionChange = (option: string) => {
        setSelectedOption(option);
        // Potentially reset the date here if needed when the option changes
    };

    // Determine the date picker format based on the selected option
    const getPickerFormat = () => {
        switch (selectedOption) {
            case 'H': return 'hour';
            case 'D': return 'hour';
            case 'W': return 'week';
            case 'M': return 'month';
            case 'Y': return 'year';
            default: return 'date';
        }
    };

    // Adjust the DatePicker based on the selected time frame
    const renderDatePicker = () => {
        let datePickerProps: any = {
            selected: startDate,
            onChange: (date: Date) => setStartDate(date),
            className: "form-input border-1 mb-4 border-gray-300 p-2 rounded-md shadow-sm focus:border-blue-500",
            locale: 'en-GB',
        };

        // Depending on the picker type you might need different props
        // such as 'showWeekNumbers' for week, or 'showMonthYearPicker' for month
        // This is where you would set those props based on the `selectedOption`.

        return <DatePicker {...datePickerProps} />;
    };

    return (
        <div className=''>
            <div className="flex justify-end items-center space-x-4">
                <div className="flex items-center mb-4 border-1">
                    {["H", "D", "W", "M", "Y"].map((option) => (
                        <div key={option}>
                            <input
                                type="radio"
                                id={`option-${option}`}
                                value={option}
                                checked={selectedOption === option}
                                onChange={() => handleOptionChange(option)}
                                className="hidden peer"
                            />
                            <label
                                htmlFor={`option-${option}`}
                                className={`inline-flex items-center justify-center w-full py-2 px-3 text-gray-500 bg-white border border-gray-300 rounded-sm cursor-pointer hover:bg-blue-100 peer-checked:bg-brand-blue-light peer-checked:text-white`}
                            >
                                <div className="block text-sm font-semibold">{option}</div>
                            </label>
                        </div>
                    ))}
                </div>
                {renderDatePicker()}
            </div>
            <div className='grid grid-cols-12 gap-6'>
                <div className="col-span-7">
                    <Card title="Walk-In Analysis">
                        <WalkInBarGraph />
                    </Card>
                </div>
                <div className="col-span-5">
                    <div className='grid grid-rows-2 gap-4' style={{ height: '100%' }}>
                        <Card title="Age based analysis">
                            <VisitorAgeChart />
                        </Card>
                        <Card title="Gender based analysis">
                            <GenderPieChart />
                        </Card>
                    </div>
                </div>
                <div className="col-span-12">
                    <Card title="Live Congestion Status">
                        <DepartmentWaffleCharts />
                    </Card>
                </div>



                <div className="col-span-6">
                    <Card title="Live Wait time Stats">
                        <DepartmentWaitTime />
                    </Card>
                </div>
                <div className="col-span-6">
                    <Card title="TimeSpent Stats">
                        <WaitTimeLineChart />
                    </Card>
                </div>
                <div className="col-span-12">
                    <Card title="Patient Flow analysis">
                        <SankeyDiagram />
                    </Card>
                </div>
                <div className="col-span-12">
                    <Card title="Hygiene analysis">
                        <HygieneAnalysis
                            overallStatus='Good'
                            dustStatus="Good"
                            wasteStatus="Moderate"
                            maskStatus="Good"
                            onAnalyzeClick={() => { }}
                        />
                    </Card>
                </div>
                <div className="col-span-12">
                    <Card title="Emotion analysis">
                        <EmotionLineChart />
                    </Card>
                </div>


            </div>
        </div>
    );
};

export default GeneralTab;
