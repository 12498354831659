import React from 'react';
import { ResponsivePie } from '@nivo/pie';
import { theme } from '../constants';

// Tableau color palette
const tableauColors = ['#4e79a7', '#f28e2b', '#e15759'];

// Mock data
const data = [
  { id: 'Male', value: 55 },
  { id: 'Female', value: 38 },
  { id: 'Others', value: 7 }
];

const GenderPieChart = () => (
  <div style={{ height: 200, width  :'100%' }}>
    <ResponsivePie
      data={data}
      colors={({ id }) => {
        const index = data.findIndex(d => d.id === id);
        return tableauColors[index % tableauColors.length];
      }}
      margin={{ top: 20, right: 20, bottom: 20, left: 120 }}
      innerRadius={0.5}
      padAngle={0.7}
      arcLabelsTextColor={'#ffffff'}
      cornerRadius={3}
      activeOuterRadiusOffset={8}
      borderWidth={1}
      borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
      // arcLinkLabelsSkipAngle={10}
      // arcLinkLabelsTextColor="#333333"
      // arcLinkLabelsThickness={2}
      // arcLinkLabelsColor={{ from: 'color' }}
      // enableArcLabels
      // arcLabelsRadiusOffset={0.6}
      // arcLabelsSkipAngle={10}
      theme={theme}
      // tooltip={({ datum }) => (
      //   // <div
      //   //   style={{
      //   //     background: '#fff',
      //   //     padding: '5px 10px',
      //   //     border: '1px solid #ccc',
      //   //   }}
      //   // >
      //   //   {`${datum.id}: ${datum.value} (${datum.formattedValue})`}
      //   // </div>
      // )}
      
      legends={[
        {
          data: data.map((d,index) => {
            return {
              color: tableauColors[index],
              label: d.id +' : '+ d.value +' %',
              id: d.id
            }
          }),
          anchor: 'left',
          direction: 'column',
          justify: false,
          translateX: -100,
          translateY: 0,
          itemsSpacing: 10,
          itemWidth: 150,
          itemHeight: 30,
          itemTextColor: '#999',
          symbolSize: 20,
          effects: [
            {
              on: 'hover',
              style: {
                itemTextColor: '#000'
              }
            }
          ],
        }
      ]}
    />
  </div>
);

export default GenderPieChart;
