// DepartmentWaffleCharts.tsx
import React from 'react';
import { ResponsiveWaffle } from '@nivo/waffle';
import OverallCongestionDonut from './OverallCongestionDonut';
import Speedometer from './Speedometer';
import ReactSpeedometer from 'react-d3-speedometer';

const departmentsData = [
  { department: 'Reception', value: 42 },
  { department: 'Refraction', value: 80 },
  { department: 'Cataract', value: 58 },
  { department: 'Retina', value: 68 },
  { department: 'Pharmacy', value: 27 },
];

const waitTimeAverage = 27;
const departmentsWaitTimes = [
  { department: 'Reception', value: 5 },
  { department: 'Refraction', value: 22 },
  { department: 'Cataract', value: 9 },
  { department: 'Retina', value: 49 },
  { department: 'Pharmacy', value: 3 },


];

const tableauColors = [
  '#4E79A7', '#F28E2B', '#E15759', '#76B7B2', '#59A14F',
  '#EDC948', '#B07AA1', '#FF9DA7', '#9C755F', '#BAB0AC'
];

interface WaffleCenterLabelProps {
  value: string;
  isPercentage: boolean
}

const WaffleCenterLabel: React.FC<WaffleCenterLabelProps> = ({ value, isPercentage=true }) => {
  return (
    <div
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -20%)',
        fontSize: '24px',
        fontWeight: 'bold',
        color: '#424242',
      }}
    >
      {value + (isPercentage ?'%': '') }
    </div>
  );
};

const WaffleCenterLabelTime: React.FC<WaffleCenterLabelProps> = ({ value, isPercentage=true }) => {
  return (
    <div
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -20%)',
        fontSize: '20px',
        fontWeight: 'bold',
        color: '#424242',
      }}
    >
      {value + (isPercentage ?'%': '') }
    </div>
  );
};

// Calculate the overall congestion
const calculateOverallCongestion = (data: any[]) => {
  const totalValues = data.reduce((sum, dept) => sum + dept.value, 0);
  return Math.round(totalValues / data.length);
};

const overallCongestion = calculateOverallCongestion(departmentsData);

const DepartmentWaffleCharts: React.FC = () => {
  return (
    <div className='grid grid-cols-12 gap-3 height-[600px]'>
      <div className='ml-10 col-span-4'>
        <OverallCongestionDonut value={overallCongestion} />
      </div>
      <div className='col-span-8 flex flex-row justify-around items-center'>
        {departmentsData.map((dept, index) => (
          <div
            key={dept.department}
            style={{
              height: 200,
              width: 225,
              marginBottom: 50,
              position: 'relative',
            }}
          >
            <div style={{ textAlign: 'center', marginBottom: '10px' }}>
              <h3 className='text-gray-600'>{dept.department}</h3>
            </div>
            <ResponsiveWaffle
              total={100}
              data={[{ id: dept.department, value: dept.value, label: dept.department }]}
              rows={10}
              columns={10}
              margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
              emptyColor='#e8f5e9'
              colors={[tableauColors[index % tableauColors.length]]} // Use a specific color for each department
              borderWidth={2}
              borderColor={{ from: 'color', modifiers: [['darker', 0.3]] }}
              animate={true}

              motionConfig="stiff"
              isInteractive={true}
            />
            <WaffleCenterLabel value={String(dept.value)} isPercentage={true} />
          </div>


        ))}
      </div>
      <div className='ml-10 col-span-4'>
        <div className='flex flex-col items-center justify-center bg-blue-100 rounded-2xl p-5' >

          <ReactSpeedometer
            value={waitTimeAverage}
            height={200}
            width={300}
            segments={3}
            minValue={0}
            maxValue={100}
            segmentValueFormatter={(s) => s+ ' mins'}
            currentValueText={`Wait time: ${waitTimeAverage} mins`}
            customSegmentStops={[0, 45, 75, 100]}
            needleColor="steelblue"
            segmentColors={[
              "#a3be8c",
              "#ebcb8b",
              "#d08770",
            ]}
          />
          <div className='mt-2 text-center text-2xl text-gray-600'>
            Average Wait time
          </div>
        </div>
      </div>
      <div className='col-span-8 flex flex-row justify-around items-center'>
        {departmentsWaitTimes.map((dept, index) => (
          <div
            key={dept.department}
            style={{
              height: 200,
              width: 225,
              marginBottom: 50,
              position: 'relative',
            }}
          >
            <div style={{ textAlign: 'center', marginBottom: '10px' }}>
              <h3 className='text-gray-600'>{dept.department}</h3>
            </div>
            <ResponsiveWaffle
              total={60}
              data={[{ id: dept.department, value: dept.value, label: dept.department }]}
              rows={10}
              columns={10}
              margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
              emptyColor='#e8f5e9'
              colors={[tableauColors[index % tableauColors.length]]} // Use a specific color for each department
              borderWidth={2}
              borderColor={{ from: 'color', modifiers: [['darker', 0.3]] }}
              animate={true}

              motionConfig="stiff"
              isInteractive={true}
            />
            <WaffleCenterLabelTime value={(dept.value+ ' mins')} isPercentage={false}/>
            {/* <ReactSpeedometer
            value={waitTimeAverage}
            height={150}
            width={150}
            segments={3}
            minValue={0}
            maxValue={100}
            segmentValueFormatter={(s) => s+ ' mins'}
            currentValueText={`Wait time: ${waitTimeAverage} mins`}
            customSegmentStops={[0, 45, 75, 100]}
            needleColor="steelblue"
            segmentColors={[
              "#a3be8c",
              "#ebcb8b",
              "#d08770",
            ]}
          /> */}
        
          </div>


        ))}
      </div>
    </div>

  );
};

export default DepartmentWaffleCharts;
