// OverallCongestionDonut.tsx
import React from 'react';
import { ResponsivePie } from '@nivo/pie';

const OverallCongestionDonut: React.FC<{ value: number }> = ({ value }) => {
  const data = [
    { id: 'congested', label: 'Occupied', value },
    { id: 'free', label: 'Free', value: 100 - value },
  ];

  return (
    <div className='w-full h-[300px] flex flex-col items-center justify-center bg-blue-100 rounded-2xl p-2 mt-5 relative'>
      <ResponsivePie
        data={data}
        innerRadius={0.6}
        margin={{ top: 20, right: 50, bottom: 10, left: 0 }}
        colors={['#e15759', '#4caf50']} // Customize colors
        arcLabelsTextColor={'#ffffff'}
        enableArcLinkLabels={false}
        legends={[
          {
              anchor: 'right',
              direction: 'column',
              justify: false,
              translateX: 120,
              translateY: 80,
              itemsSpacing: 1,
              itemWidth: 150,
              itemHeight: 30,
              itemTextColor: '#999',
              itemDirection: 'left-to-right',
              itemOpacity: 1,
              symbolSize: 15,
              symbolShape: 'circle',
              effects: [
                  {
                      on: 'hover',
                      style: {
                          itemTextColor: '#000'
                      }
                  }
              ]
          }
      ]}
      />
      <div className='text-4xl font-bold text-center text-gray-600 absolute -translate-y-4 -translate-x-4'>
        {value}%
      </div>
      <div className='mt-2 text-center text-2xl text-gray-600'>
        Overall Occupancy
      </div>
    </div>
    
  );
};

export default OverallCongestionDonut;
