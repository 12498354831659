import React, { useState } from 'react';
import { FiUser, FiBell, FiInfo } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

const TopBar = () => {
    const [showLogout, setShowLogout] = useState(false);
    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.removeItem('token'); // Remove the stored token
        navigate('/login'); // Navigate to login page
    };
    
    return (
        <div className="bg-white shadow w-full h-16 flex justify-between items-center px-6">
            <div className="text-xl flex flex-row font-semibold items-center justify-center">
                <p className='text-gray-600 text-center'>Neurawatch Platform</p> <FiInfo size={15} className='text-gray-600 ml-3' />
            </div>
            <div className="flex items-center mr-5">
                <div className='mr-5'>
                    <input type="text" placeholder="Search..." className="p-1 border border-gray-300 rounded-lg" />
                </div>
                <div className="relative mr-4 cursor-pointer">
                    <FiBell size={24} />
                    <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-red-600 rounded-full">5</span>
                </div>
                <div className="relative ml-4 cursor-pointer">
                    <div onClick={() => setShowLogout(!showLogout)}>
                        <FiUser size={24} />
                    </div>
                    {showLogout && (
                        <button onClick={handleLogout} className="absolute top-full right-0 mt-2 bg-white border border-gray-300 shadow px-4 py-2 rounded-lg text-sm">
                            Logout
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default TopBar;
