// import React from 'react';
// import './App.css';
// import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import { Sidebar, SidebarItem } from './components/Sidebar';
// import { FiHome, FiGrid, FiClipboard, FiCalendar, FiSettings, FiLifeBuoy, FiPieChart } from 'react-icons/fi';

// // Page components
// import Home from './pages/Home';
// import Alerts from './pages/Alerts';
// import Cameras from './pages/Cameras';
// import Settings from './pages/Settings';
// import Help from './pages/Help';
// import Analytics from './pages/Analytics';
// import TopBar from './components/TopBar';

// function App() {
//   return (
//     <Router>
//       <div className="flex tk-proxima-nova">
//         <Sidebar>
//           <SidebarItem icon={<FiHome size={20} />} text="Home" link="/" itemId="home" />
//           <SidebarItem icon={<FiPieChart size={20} />} text="Analytics" link="/analytics" itemId="analytics" />
//           <SidebarItem icon={<FiClipboard size={20} />} text="Alerts" link="/alerts" itemId="alerts" />
//           <SidebarItem icon={<FiCalendar size={20} />} text="Cameras" link="/cameras" itemId="cameras" />
//           <hr className="my-3" />
//           <SidebarItem icon={<FiSettings size={20} />} text="Settings" link="/settings" itemId="settings" />
//           <SidebarItem icon={<FiLifeBuoy size={20} />} text="Help" link="/help" itemId="help" />
//         </Sidebar>
//         <div className="flex-1 flex flex-col">
//           <TopBar />
//           <main className="flex-1">
//             <Routes>
//               <Route path="/" element={<Home />} />
//               <Route path="/analytics" element={<Analytics />} />
//               <Route path="/alerts" element={<Alerts />} />
//               <Route path="/cameras" element={<Cameras />} />
//               <Route path="/settings" element={<Settings />} />
//               <Route path="/help" element={<Help />} />
//             </Routes>
//           </main>
//         </div>
//       </div>
//     </Router>
//   );
// }

// export default App;

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import LoginPage from './pages/LoginPage';
import Main from './pages/main';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);  // New state to handle loading

  useEffect(() => {
    const token = localStorage.getItem('token');
    console.log('Token:', token);
    setIsAuthenticated(!!token);  // Set authentication based on token presence
    setLoading(false);  // Set loading to false after checking the token
  }, []);

  if (loading) {
    return <div>Loading...</div>;  // Or some other loading indicator
  }

  return (
    <Router>
      <Routes>
      <Route path="/login" element={isAuthenticated? <Navigate to="/home" />: <LoginPage />} />
      <Route path="*" element={<Main />} />
      </Routes>
    
    </Router>
  );
}

export default App;
