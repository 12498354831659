import React, { useState } from 'react';
import AlertCard from './AlertCard';
import { FaUserNurse, FaFire, FaHeartbeat } from 'react-icons/fa';



const EmergencyCodesTab: React.FC = () => {

  const [enabled, setEnabled] = useState(true);

  const toggleEnabled = () => setEnabled(!enabled);

  return (
    <div className='flex flex-col flex-wrap justify-center items-center bg-white mt-10 shadow rounded-3xl p-4'>
      <div className='flex flex-row justify-start w-full p-2'>
        <div className='text-gray-800 text-xl mx-4 py-2'>Emergency Codes</div>
        <div>
          <button
            onClick={() => setEnabled(false)}
            className={`py-2 px-4 rounded-l-full ${!enabled ? 'bg-red-500 text-white' : 'bg-gray-200 text-gray-600'}`}
          >
            Disabled
          </button>
          <button
            onClick={() => setEnabled(true)}
            className={`py-2 px-4 rounded-r-full ${enabled ? 'bg-green-500 text-white' : 'bg-gray-200 text-gray-600'}`}
          >
            Enabled
          </button>
        </div>
      </div>
      <div className='flex flex-row flex-wrap gap-4 justify-center bg-white mt-5'>
        <AlertCard
          title="Code Red"
          cases={0}
          icon={<FaFire style={{ fontSize: '80px' }} />}
          color="#b80f0a"
        />
        <AlertCard
          title="Code Blue"
          cases={0}
          icon={<FaHeartbeat style={{ fontSize: '80px' }} />}
          color="#0f4392"
        />
        <AlertCard
          title="Code White"
          cases={0}
          icon={<FaUserNurse style={{ fontSize: '80px' }} />}
          color="gray"
        />
      </div>
    </div>
  );
};

export default EmergencyCodesTab;
