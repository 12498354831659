import React from 'react';

interface AlertCardProps {
  title: string;
  cases: number;
  icon: React.ReactNode;
  color?: string;
}

const AlertCard: React.FC<AlertCardProps> = ({ title, cases, icon, color = '#888' }) => {
  // Generate a linear gradient background style
  const gradientStyle = {
    backgroundColor: `${color}`,
    backgroundSize: '20px 20px',
    backgroundPosition: '0 0, 10px 10px',
  };

  return (
    <div
      className="rounded-xl p-4 m-2 w-80 bg-white text-white shadow-lg"
      style={gradientStyle} // Apply the gradient style here
    >
      <div className="text-2xl text-center font-semibold mb-2">{title}</div>
      <div className="flex flex-row gap-2">
        <div className="flex items-center">
          <div className="text-6xl text-white">{icon}</div>
        </div>
        <div className="flex flex-col justify-center items-center grow">
          <div className="text-5xl font-normal text-white  mb-1 mt-3">{cases}</div>
          <div className="text-lg font-normal text-white mb-3">Cases reported</div>
          <div className="border-t w-full border-white m-2 px-10 border-1"></div>

          <div className="text-[0.7rem] font-normal text-white mb-1">No Cases reported in last 1 week</div>
        </div>
      </div>
    </div>
  );
};

export default AlertCard;
