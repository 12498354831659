export type WalkInData = {
    scale: string;  // 'Day', 'Hour', 'Month'
    time: string;   // '00:00', '01:00' for Hour, etc.
    walkIns: number;
};

export const getWalkInData = (): WalkInData[] => {
    return [
        // Hourly data (6 samples)
        { scale: "Minute", time: "10:10", walkIns: 15 },
        { scale: "Minute", time: "10:20", walkIns: 30 },
        { scale: "Minute", time: "10:30", walkIns: 25 },
        { scale: "Minute", time: "10:40", walkIns: 20 },
        { scale: "Minute", time: "10:50", walkIns: 10 },
        { scale: "Minute", time: "11:00", walkIns: 5 },

        // Daily data (6 samples)
        { scale: "Day", time: "Monday", walkIns: 150 },
        { scale: "Day", time: "Tuesday", walkIns: 180 },
        { scale: "Day", time: "Wednesday", walkIns: 165 },
        { scale: "Day", time: "Thursday", walkIns: 170 },
        { scale: "Day", time: "Friday", walkIns: 200 },
        { scale: "Day", time: "Saturday", walkIns: 220 },

        // Monthly data (6 samples)
        { scale: "Month", time: "January", walkIns: 1200 },
        { scale: "Month", time: "February", walkIns: 1100 },
        { scale: "Month", time: "March", walkIns: 1150 },
        { scale: "Month", time: "April", walkIns: 1300 },
        { scale: "Month", time: "May", walkIns: 1400 },
        { scale: "Month", time: "June", walkIns: 1350 },
    ];
};

export type VisitorData = {
    name: string;
    value: number;
  };
  
  export const getPieChartData = (): VisitorData[] => {
    return [
      { name: 'Age 1-20', value: 300 },
      { name: 'Age 20-40', value: 450 },
      { name: 'Age 40-60', value: 500 },
      { name: 'Age 60+', value: 350 }
    ];
  };

  export type EmotionData = {
    time: string;
    level: number;
    sad:number;
    neutral:number;
    happy:number;
  };
  
  export const getEmotionData = (): EmotionData[] => {
    return [  
      { time: "10:00", level: 2, sad:7, happy:35, neutral: 58 },
      { time: "10:30", level: 1.8, sad:10, happy:28, neutral: 62 },
      // { time: "11:00", level: 1, angry:4, happy:14, neutral: 20 },
      // { time: "12:00", level: 2, angry:2, happy:12, neutral: 50 },
      // { time: "1:00", level: 2.4, angry:4, happy:5, neutral: 15 },
      // { time: "2:00", level: 1.5, angry:6, happy:20, neutral: 40 }      
    ];
  };

  export type GenderData = {
    gender: string;
    count: number;
  };
  
  export const getGenderData = (): GenderData[] => {
    return [
      { gender: 'Male', count: 500 },
      { gender: 'Female', count: 450 },
    ];
  };

  export type WaitTimeData = {
    time: string;  // Time of day or date
    waitTime: number;  // Average wait time in minutes
  };
  
  export const getWaitTimeData = (): WaitTimeData[] => {
    return [
      { time: "08:00", waitTime: 10 },
      { time: "09:00", waitTime: 15 },
      { time: "10:00", waitTime: 20 },
      { time: "11:00", waitTime: 18 },
      { time: "12:00", waitTime: 25 },
      { time: "13:00", waitTime: 30 },
      { time: "14:00", waitTime: 22 },
      // Continue for each time period
    ];
  };
  
  export type SankeyNode = {
    name: string;
  };
  
  export type SankeyLink = {
    source: number;  // Index of the source node in the nodes array
    target: number;  // Index of the target node in the nodes array
    value: number;   // Magnitude of flow from source to target
  };
  
  export const getSankeyData = () => {
    const nodes: SankeyNode[] = [
      { name: "Entrance" },
      { name: "Refraction" },
      { name: "Retina Consultation" },
      { name: "Cataract Consultation" },
      { name: "Pharmacy" }
    ];
  
    const links: SankeyLink[] = [
      { source: 0, target: 1, value: 100 },
      { source: 1, target: 2, value: 30 },
      { source: 1, target: 3, value: 70 },
      { source: 2, target: 4, value: 25 },
      { source: 3, target: 4, value: 45 }
    ];
  
    return { nodes, links };
  };
  
  // In your mockDataService.ts or wherever you manage data
  export const getCloggingData = () => {
    return [
      { name: "Refraction", value: 100, fill: "#FF4500" }, // Red
      { name: "Retina Consultation", value: 100, fill: "#FFD700" }, // Yellow
      { name: "Cataract Consultation", value: 100, fill: "#3CB371" }, // Green
      { name: "Pharmacy", value: 100, fill: "#228B22" } // Darker Green
    ];
  };
  
  