import React, { useState } from 'react';
import { BsInfoCircleFill } from 'react-icons/bs';

interface CardProps {
    title: string;
    children: React.ReactNode;
    footer?: React.ReactNode;
    infoText?: string;
}

const Card: React.FC<CardProps> = ({ title, children, footer, infoText="sample" }) => {
    const [showInfo, setShowInfo] = useState(false);

    return (
        <div className=" bg-white shadow rounded-3xl p-4">
            <div className="flex flex-row justify-start">
            <h5 className="text-lg text-gray-800 mb-2">{title}</h5>
            {infoText && (
                <div className="ml-4">
                    <button
                        className="text-gray-400 mr-8 mt-2 hover:text-gray-800 w-8"
                        onClick={() => setShowInfo(!showInfo)}
                    >   
                        <BsInfoCircleFill />
                    </button>
                    {showInfo && (
                        <div className="absolute top-full right-0 bg-brand-blue-light text-white p-2 rounded-md text-sm">
                            {infoText}
                        </div>
                    )}
                </div>
            )}
            </div>
            <div className='flex flex-col flex-grow'>{children}</div>
            {footer && <div className="mt-4 pt-4 border-t">{footer}</div>}
        </div>
    );
};

export default Card;