import React from "react"
import { FaCamera, FaExclamationTriangle } from 'react-icons/fa';


function Settings() {
    return (
        <div className="p-4 mx-10 mt-20">
          <div className="border-2 border-red-500 bg-red-100 p-4 rounded-lg flex items-center gap-4">
            <FaExclamationTriangle className="text-red-500 text-3xl" />
            <div>
              <h2 className="text-lg font-semibold flex items-center gap-2">
               Settings disabled for this org
              </h2>
              <p className="text-sm text-gray-600">Kindly contact admin to enable this option.</p>
            </div>
          </div>
        </div>
      );
}

export default Settings;