export const theme = {
    labels: {
        text: {
            fontFamily: 'Montserrat',
          fontSize: 16, // Set your desired font size here
          fill: '#fff', // Optional, set text color if needed
        },
      }, 
    axis: {
        ticks: {
            line: {
                stroke: '#eee',
                strokeWidth: 1
            },
            text: {
                fontFamily: 'Montserrat',
                fontSize: 14,
                fill: '#333'
            }
        },
        legend: {
            text: {
                fontFamily: 'Montserrat',
                fontSize: 16,
            },
        },
           
    }
};