import React from "react"
import { FiHome, FiClipboard, FiCalendar, FiSettings, FiPieChart,FiLifeBuoy, FiAlertCircle, FiAlertOctagon, FiCamera } from "react-icons/fi";
import { FcCallback , FcHome, FcAutomatic, FcIntegratedWebcam    } from "react-icons/fc";

import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import { Sidebar, SidebarItem } from "../components/Sidebar";
import TopBar from "../components/TopBar";
import Alerts from "./Alerts";
import Cameras from  "./Cameras";
import Home from "./Home";
import Settings from "./Settings";
import Help from "./Help";
import Analytics from "./Analytics";


function Main() {
    return (
        <div className="flex tk-proxima-nova">
          <Sidebar>
           <SidebarItem icon={<FiHome size={20} />} text="Home" link="/" itemId="home" />
           <SidebarItem icon={<FiPieChart size={20} />} text="Real-Time Analytics" link="/analytics" itemId="analytics" />
           <SidebarItem icon={<FiAlertOctagon size={20} />} text="Alerts" link="/alerts" itemId="alerts" />
           {/* <SidebarItem icon={<FiCamera size={20} />} text="Cameras" link="/cameras" itemId="cameras" /> */}
           <hr className="my-3" />
           <SidebarItem icon={<FiSettings size={20} />} text="Settings" link="/settings" itemId="settings" />
                     <SidebarItem icon={<FiLifeBuoy size={20} />} text="Help" link="/help" itemId="help" />
         </Sidebar>
          <div className="flex-1 flex flex-col">
            <TopBar />
            <main className="flex-1">
              <Routes>
                <Route element={<PrivateRoutes />} >
                    <Route path="/home" element={<Home />} />
                    <Route path="/alerts" element={<Alerts />} />
                    <Route path="/analytics" element={<Analytics />} />
                    {/* <Route path="/cameras" element={<Cameras />} /> */}
                    <Route path="/settings" element={<Settings />} />
                    <Route path="/help" element={<Help />} />
                    <Route path="*" element={<Navigate to="/home" />} />
                </Route>
              </Routes>
            </main>
          </div>
        </div>
      );
}

export default Main;

const PrivateRoutes = () => {
    const token = localStorage.getItem('token');
    return (token? <Outlet />: <Navigate to="/login" />)
}