import React, { FC, MouseEvent } from 'react';

interface CustomBarComponentProps {
  bar: any;
  handleMouseEnter: (bar: any, event: MouseEvent<SVGGElement>) => void;
  handleMouseLeave: () => void;
  onClick: (bar:any) => void;
  hoveredBarIndex: number | null;
}
export const CustomBarComponent: FC<CustomBarComponentProps> = ({ bar, handleMouseEnter, handleMouseLeave, hoveredBarIndex, onClick }) => {
    const opacity = hoveredBarIndex === null || hoveredBarIndex === bar.index ? 1 : 0.4;
    // console.log(bar)
    return (
      <g
        transform={`translate(${bar.x}, ${bar.y})`}
        cursor={'pointer'}
      >
        <rect
          width={bar.width}
          height={bar.height}
          fill={bar.color}
          rx={10}
          onMouseEnter={(e) => handleMouseEnter(bar, e)}
        onMouseLeave={() => handleMouseLeave()}
          opacity={opacity}
          onClick={onClick}
        />
        <text
          x={bar.width / 2}
          y={bar.height / 2}
          dy=".35em"
          textAnchor="middle"
          fill="#fff" // Adjust the color based on your chart's style
          fontSize={14} // Adjust the size based on your needs
        //   fontWeight="bold"
        >
          {bar.data.value} {/* Adjust the property to your actual data field */}
        </text>
      </g>
    );
  };