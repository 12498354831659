import React, { createContext, useState, FC, ReactNode, useContext } from 'react';
import { Link } from 'react-router-dom';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import logo from '../assets/logo-full.svg';  // Ensure you have your logo at this path
import { useLocation } from 'react-router-dom'; // Import useLocation

// Context interface
interface SidebarContextType {
    expanded: boolean;
    setExpanded: (expanded: boolean) => void;
    activeItem: string;
    setActiveItem: (itemId: string) => void;
}

// Create the context with default values
const SidebarContext = createContext<SidebarContextType>({
    expanded: true,
    setExpanded: () => { },
    activeItem: '',
    setActiveItem: () => { }
});

// Custom hook to use the sidebar context
export const useSidebar = () => useContext(SidebarContext);

interface SidebarProps {
    children: ReactNode;
}

// Sidebar component
export const Sidebar: FC<SidebarProps> = ({ children }) => {
    const [expanded, setExpanded] = useState<boolean>(true);
    const location = useLocation(); // Get current location
    const [activeItem, setActiveItem] = useState<string>(location.pathname); // Set active item based on current path

    // Update activeItem when location changes
    React.useEffect(() => {
        setActiveItem(location.pathname);
    }, [location]);

    return (
        <SidebarContext.Provider value={{ expanded, setExpanded, activeItem, setActiveItem }}>
            <aside className="h-screen flex flex-col">
                <nav className="h-full flex flex-col bg-white border-r shadow-sm">
                    <div className="p-4 pb-8 flex justify-between items-center">
                        <img
                            src={logo}
                            alt="Logo"
                            className={`transition-all duration-300 ease-in-out ${expanded ? "w-24" : "w-0"
                                } overflow-hidden`}
                        />
                        <button onClick={() => setExpanded(curr => !curr)} className="p-1.5 rounded-lg bg-gray-50 hover:bg-gray-100">
                            {expanded ? <FiChevronLeft size={24} /> : <FiChevronRight size={24} />}
                        </button>
                    </div>
                    <ul className="flex-1 px-3">
                        {children}
                    </ul>
                </nav>
            </aside>
        </SidebarContext.Provider>
    );
};

interface SidebarItemProps {
    icon: React.ReactElement;
    text: string;
    link: string;
    itemId: string;
}

export const SidebarItem: FC<SidebarItemProps> = ({ icon, text, link, itemId }) => {
    const { expanded, activeItem, setActiveItem } = useSidebar();
    const isActive = link === activeItem; // Change this line to compare to link

    return (
        <Link to={link} className="w-full text-decoration-none" onClick={() => setActiveItem(link)}>
            <li className={`relative flex items-center py-2 px-3 my-1 font-medium rounded-md cursor-pointer transition-colors group ${isActive ? "bg-gradient-to-tr from-blue-200 to-blue-100 text-blue-800" : "hover:bg-indigo-50 text-gray-600"}`}>
                {icon}
                <span className={`overflow-hidden transition-all ${expanded ? "w-32 ml-3" : "w-0"}`}>{text}</span>
            </li>
        </Link>
    );
};