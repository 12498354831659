import React from 'react';
import { ResponsiveSankey } from '@nivo/sankey';
import { theme } from '../constants';

// Tableau 10 color palette
const tableauColors = [
    '#4e79a7', '#f28e2b', '#e15759', '#76b7b2',
    '#59a14f', '#edc948', '#b07aa1', '#ff9da7',
    '#9c755f', '#bab0ab'
];

const data = {
    nodes: [
        { id: "Entrance" },
        { id: "Refraction" },
        { id: "Retina Consultation" },
        { id: "Cataract Consultation" },
        { id: "Pharmacy" },
        { id: "Exit" }
    ],
    links: [
        { source: "Entrance", target: "Refraction", value: 90 },
        { source: "Refraction", target: "Retina Consultation", value: 30 },
        { source: "Refraction", target: "Cataract Consultation", value: 60 },
        { source: "Retina Consultation", target: "Pharmacy", value: 30 },
        { source: "Cataract Consultation", target: "Exit", value: 60 },
        { source: "Pharmacy", target: "Exit", value: 30 },
        { source: "Entrance", target: "Exit", value: 10 }
    ]
};

const SankeyDiagram = () => (<div>
    

    <div style={{ position: 'relative',height: 400, width: '100%' }}>
        <ResponsiveSankey
            data={data}
            margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
            align="center"
            colors={({ index }) => tableauColors[index % tableauColors.length]}
            nodeOpacity={1}
            nodeHoverOpacity={0.8}
            nodeThickness={25}
            nodeInnerPadding={5}
            nodeSpacing={36}
            labelPosition="inside"
            labelOrientation="horizontal"
            labelTextColor="white"
            linkOpacity={0.7}
            linkHoverOpacity={0.9}
            //  enableLinkGradient
            enableLabels
            label={(d) => `${d.id}: ${d.value}%`}
            theme={theme}
        />
        <div style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255,255,255,0.4)', // Light semi-transparent overlay
            backdropFilter: 'blur(1px)', // Apply blur effect
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'black',
            fontSize: '24px',
            fontWeight: 'bold',
            // textShadow: '0 2px 4px rgba(0,0,0,0.5)' // Text shadow for better readability
        }}>Data not available</div>
    </div>
</div>
);

export default SankeyDiagram;
