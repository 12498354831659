import React, { useState } from 'react';
import AlertCard from './AlertCard';
import { FaHandsWash, FaHeadSideMask, FaStreetView } from 'react-icons/fa';

const PandemicControlTab: React.FC = () => {
  const [enabled, setEnabled] = useState(false);

  const toggleEnabled = () => setEnabled(!enabled);
  return (
    <div className='flex flex-col flex-wrap justify-center items-center bg-white mt-10 p-4'>
      <div className='flex flex-row justify-start w-full p-2'>
        <div className='text-gray-800 text-xl mx-4 py-2'>Pandemic control</div>
        <div>
          <button
            onClick={() => setEnabled(false)}
            className={`py-2 px-4 rounded-l-full ${!enabled ? 'bg-red-500 text-white' : 'bg-gray-200 text-gray-600'}`}
          >
            Disabled
          </button>
          <button
            onClick={() => setEnabled(true)}
            className={`py-2 px-4 rounded-r-full ${enabled ? 'bg-green-500 text-white' : 'bg-gray-200 text-gray-600'}`}
          >
            Enabled
          </button>
        </div>
      </div>
      <div className='flex flex-row flex-wrap gap-4 justify-center bg-white mt-5'>
        <AlertCard
          title="Mask Violations"
          cases={0}
          icon={<FaHeadSideMask style={{ fontSize: '80px' }} />}
          color="gray"
        />
        <AlertCard
          title="Social Distancing Violations"
          cases={0}
          icon={<FaStreetView style={{ fontSize: '80px' }} />}
          color="gray"
        />
        <AlertCard
          title="Sanitisation Violations"
          cases={0}
          icon={<FaHandsWash style={{ fontSize: '80px' }} />}
          color="gray"
        />
      </div>
    </div>
  );
};

export default PandemicControlTab;
