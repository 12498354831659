// import React from "react"
// import { FaArrowCircleRight, FaCamera, FaCameraRetro, FaHandsWash, FaHeadSideMask, FaObjectGroup, FaStore, FaStreetView } from 'react-icons/fa';
// import CameraTable from "../components/CameraTable";


// interface AlertCardCustomProps {
//     title: string;
//     cases: string;
//     subText: string;
//     icon: React.ReactNode;
//     color?: string;
//   }
  
//   const AlertCardCustom: React.FC<AlertCardCustomProps> = ({ title, cases,subText, icon, color = '#888' }) => {
//     // Generate a linear gradient background style
//     const gradientStyle = {
//       backgroundColor: `${color}`,
//       backgroundSize: '20px 20px',
//       backgroundPosition: '0 0, 10px 10px',
//     };
  
//     return (
//         <div className="container mx-auto p-4">
//         <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-10">
//           <div className="p-4 bg-blue-500 text-white border rounded-md shadow flex items-center">
//             <FaTags className="text-4xl mr-4" />
//             <div>
//               <h3 className="text-lg font-semibold">Total Tags</h3>
//               <p className="text-2xl">{totalTags}</p>
//             </div>
//           </div>
//           <div className="p-4 bg-green-500 text-white border rounded-md shadow flex items-center">
//             <FaCamera className="text-4xl mr-4" />
//             <div>
//               <h3 className="text-lg font-semibold">Total Cameras</h3>
//               <p className="text-2xl">{totalCameras}</p>
//             </div>
//           </div>
//           <div className="p-4 bg-indigo-500 text-white border rounded-md shadow flex items-center">
//             <FaCheckCircle className="text-4xl mr-4" />
//             <div>
//               <h3 className="text-lg font-semibold">Connected Cameras</h3>
//               <p className="text-2xl">{connectedCameras}</p>
//               <p>{connectedPercentage}%</p>
//             </div>
//           </div>
//           <div className="p-4 bg-red-500 text-white border rounded-md shadow flex items-center">
//             <FaTimesCircle className="text-4xl mr-4" />
//             <div>
//               <h3 className="text-lg font-semibold">Disconnected Cameras</h3>
//               <p className="text-2xl">{disconnectedCameras}</p>
//               <p>{disconnectedPercentage}%</p>
//             </div>
//           </div>
//         </div>
  
//         <CameraTable cameras={cameras}/>
//       </div>
//     )
// }

// export default Home;

import React from 'react';
import { FaTags, FaCamera, FaCheckCircle, FaTimesCircle } from 'react-icons/fa';

import CameraTable from '../components/CameraTable';

export interface Camera {
  id: number;
  channel: string;
  rtspUrl: string;
  tag: string;
  status: 'connected' | 'disconnected';
}

const Home = () => {
  const cameras:Camera[] = [
    { id: 1, channel: 'Main Entrance', rtspUrl: 'rtsp://neurawatch.ai/videostream/1', tag: 'Entrance', status: 'connected' },
    { id: 2, channel: 'Cataract', rtspUrl: 'rtsp://neurawatch.ai/videostream/2', tag: 'Cataract', status: 'connected' },
    { id: 3, channel: 'Retina', rtspUrl: 'rtsp://neurawatch.ai/videostream/3', tag: 'Retina', status: 'connected' },
    { id: 4, channel: 'Refraction', rtspUrl: 'rtsp://neurawatch.ai/videostream/4', tag: 'Refraction', status: 'connected' },
    { id: 5, channel: 'Pharmacy', rtspUrl: 'rtsp://neurawatch.ai/videostream/5', tag: 'Pharmacy', status: 'connected' },

    // Add more initial data if needed
  ]
  const totalCameras = cameras.length;
  const connectedCameras = cameras.filter(camera => camera.status === 'connected').length;
  const disconnectedCameras = totalCameras - connectedCameras;
  const uniqueTags = new Set(cameras.map(camera => camera.tag));
  const totalTags = uniqueTags.size;

  const connectedPercentage = ((connectedCameras / totalCameras) * 100).toFixed(2);
  const disconnectedPercentage = ((disconnectedCameras / totalCameras) * 100).toFixed(2);


  return (
    <div className="container mx-auto p-4">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-10">
        <div className="p-4 bg-blue-500 text-white border rounded-md shadow flex items-center">
          <FaTags className="text-4xl mr-4" />
          <div>
            <h3 className="text-lg font-semibold">Total Tags</h3>
            <p className="text-2xl">{totalTags}</p>
          </div>
        </div>
        <div className="p-4 bg-green-500 text-white border rounded-md shadow flex items-center">
          <FaCamera className="text-4xl mr-4" />
          <div>
            <h3 className="text-lg font-semibold">Total Cameras</h3>
            <p className="text-2xl">{totalCameras}</p>
          </div>
        </div>
        <div className="p-4 bg-indigo-500 text-white border rounded-md shadow flex items-center">
          <FaCheckCircle className="text-4xl mr-4" />
          <div>
            <h3 className="text-lg font-semibold">Connected Cameras</h3>
            <p className="text-2xl">{connectedCameras}</p>
            <p>{connectedPercentage}%</p>
          </div>
        </div>
        <div className="p-4 bg-red-500 text-white border rounded-md shadow flex items-center">
          <FaTimesCircle className="text-4xl mr-4" />
          <div>
            <h3 className="text-lg font-semibold">Disconnected Cameras</h3>
            <p className="text-2xl">{disconnectedCameras}</p>
            <p>{disconnectedPercentage}%</p>
          </div>
        </div>
      </div>

      <CameraTable cameras={cameras}/>
    </div>
  );
};


export default Home;