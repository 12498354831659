// import React from 'react';
// import {
//   ComposedChart,
//   Bar,
//   Line,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
//   Legend,
//   BarProps,
// } from 'recharts';

// // Example data
// const data = [
//     { department: 'Reception', count: 40, waitTime: 45 },
//   { department: 'Cataract', count: 30, waitTime: 45 },
//   { department: 'Retina', count: 20, waitTime: 30 },
//   { department: 'Pharmacy', count: 40, waitTime: 50 },
//   { department: 'Refraction', count: 50, waitTime: 20 },
// ];

// // Tableau color palette
// const tableauColors = {
//   bar: '#4E79A7', // Blue
//   line: '#F28E2B', // Orange
//   barBorder: '#000000', // Black border for bars
// };
// const colors = [
//   '#4e79a7', '#e15759', '#76b7b2',
//   '#59a14f', '#edc948', '#b07aa1', '#ff9da7',
//   '#9c755f', '#bab0ab'
// ];

// const CustomBarShape = (props: BarProps & { index: number }) => {
//   const { x, y, width, height, index } = props;
//   const colors = [
//     '#4e79a7', '#e15759', '#76b7b2',
//     '#59a14f', '#edc948', '#b07aa1', '#ff9da7',
//     '#9c755f', '#bab0ab'
//   ];
//   const fillColor = colors[index % colors.length]; // Pick color based on index

//   return (
//     <g>
//       <rect
//         x={x}
//         y={y}
//         width={width}
//         height={height}
//         fill={fillColor}
//         rx={15} // Rounded corners
//         ry={15} // Rounded corners
//       />
//     </g>
//   );
// };
// const DepartmentWaitTime: React.FC = () => {
//   return (
//     <div style={{ width: '100%', height: 400 }}>
//       <ComposedChart
//         width={950}
//         height={400}
//         data={data}
//         margin={{
//           top: 20,
//           right: 30,
//           left: 20,
//           bottom: 5,
//         }}
//       >
//         <XAxis dataKey="department" 
//          tick={{ fontSize: 12, fontWeight: 'Bold' }}/>
//         <YAxis
//           yAxisId="left"
//           tick={{ fontSize: 12 }}
//           label={{ value: 'Visitor Count', angle: -90, position: 'insideLeft' }}
//         />
//         <YAxis
//           yAxisId="right"
//           tick={{ fontSize: 12 }}
//           orientation="right"
//           label={{ value: 'Wait Time (mins)', angle: -90, position: 'insideRight' }}
//         />
//         <Tooltip />
//         {/* <Legend /> */}
//         <Bar
//           yAxisId="left"
//           dataKey="count"
//           shape={(props: BarProps) => <CustomBarShape {...props} index={props.index as number} />}
//           name="Visitors Count"
//           barSize={20}
           
//         />
//         <Line
//           yAxisId="right"
//           dataKey="waitTime"
//           stroke={tableauColors.line}
//           name="Average Wait Time (in mins)"
//           strokeWidth={3}
//         />
//       </ComposedChart>
//     </div>
//   );
// };

// export default DepartmentWaitTime;

import React from 'react';
import { ResponsiveBar, BarCustomLayerProps, BarDatum, ComputedBarDatum } from '@nivo/bar';
import { line } from 'd3-shape';
import { Axes } from "@nivo/axes";
import { computeXYScalesForSeries } from "@nivo/scales";
import { useTooltip, TableTooltip } from "@nivo/tooltip";
import { IndexType } from 'typescript';
import { theme } from '../constants';

// Example data
const data = [
  { department: 'Reception', count: 19, waitTime: 6, i:0 },
  { department: 'Cataract', count: 18, waitTime: 10,i:1 },
  { department: 'Retina', count: 25, waitTime: 45,i:2 },
  { department: 'Pharmacy', count: 14, waitTime: 4,i:3 },
  { department: 'Refraction', count: 16, waitTime: 20,i:4 },
];

// Prepare bar data
const barData = data.map(({ department, count, waitTime,i }) => ({
  i,
  department,
  count,
  waitTime, // Include waitTime for custom layers
}));

const departments = barData.map(d => String(d.department)) 

// Custom Layer for Line
const LineLayer: React.FC<BarCustomLayerProps<BarDatum>> = ({
  bars,
  xScale,
  yScale, innerHeight, innerWidth
}) => {

  const tip = useTooltip();

  function CustomTooltip({ barValue,lineValue}:{barValue:Number, lineValue:Number}) {
    return (
      // <Tooltip
      //   rows={[
      //     ["Visitor Count", <span>{String(barValue)}</span>],
      //     ["Wait time", <span>{String(lineValue + ' mins')}</span>]
      //   ]}
      // />
      <div className="flex flex-col items-center text-[12px] shadow-lg bg-gray-800 text-white rounded-xl px-4 py-2">
          <div className="absolute -bottom-2 left-1/2 transform -translate-x-1/2 w-4 h-4 bg-gray-800 rotate-45"></div>

      <div className="flex items-center justify-between w-full">
        <p className="font-semibold mr-2">Visitor Count</p>
        <p className="font-bold">{String(barValue)}</p>
      </div>
      <div className="flex items-center justify-between w-full">
        <p className=" font-semibold mr-2">Wait Time</p>
        <p className=" font-bold">{String(lineValue) + ' mins'}</p>
      </div>
    </div>
    );
  }

  function renderTip(e:any, idx:any, x:any,y:any) {
    return tip.showTooltipAt(
      <CustomTooltip barValue={data[idx].count} lineValue={data[idx].waitTime} />,[x,y+5],'right'
    );
  }

  const scale = computeXYScalesForSeries(
    [
      {
        id: "only",
        data: data.map((it) => ({ x: it.i, y: it.waitTime}))
      }
    ],
    { type: "point" },
    { type: "linear" },
    innerWidth,
    innerHeight 
  );
  
  const lineData = bars.map(bar => ({
    indexValue: bar.data.indexValue,
    x: xScale(Number(bar.data.index)) + bar.width / 2,
    y: scale.yScale(Number(bar.data.data.waitTime)) ?scale.yScale(Number(bar.data.data.waitTime)): 0 , // Ensure `waitTime` is converted to a number
  }));


  const lineGenerator = line<{ indexValue:string | number, x: any; y: number|undefined }>()
    .x(d => d.x)
    .y(d => d.y ? d.y : 0);

  const pathData = lineGenerator(lineData);
  console.log(pathData)

  // Render path only if pathData is not null
   return pathData ? 

  ( <>
  <Axes
    yScale={scale.yScale}
    xScale={xScale}
    width={innerWidth}
    height={innerHeight}
    right={{
      ticksPosition: "after",
      legend: 'Wait time',
          legendPosition: 'middle',
          legendOffset: +50,

    }}
  />
  <Axes
    yScale={scale.yScale}
    xScale={xScale}
    width={innerWidth}
    height={innerHeight}
    bottom={{
      ticksPosition: "after",
      format:  v => departments[Number(v)],
      legend: 'Department',
          legendPosition: 'middle',
          legendOffset:35,

    }}
  />
  <path
    d={pathData}
    fill="none"
    stroke={'#F28E2B'}
    strokeWidth={3}
    style={{ pointerEvents: "none" }}
  />
  {bars.map((bar) => (<g>
    <circle
      key={bar.key}
      cx={xScale(bar.data.index) + bar.width / 2}
      cy={scale.yScale(Number(bar.data.data.waitTime))}
      r={5}
      fill="#F28E2B"
      stroke={'#F28E2B'}
      style={{ pointerEvents: "none" }}
    />
    <text
          x={xScale(bar.data.index) + bar.width / 2}
          y={scale.yScale(Number(bar.data.data.waitTime))}
          dy="-1.25em"
          textAnchor="middle"
          fill="#1F2937" // Adjust the color based on your chart's style
          fontSize={14} // Adjust the size based on your needs
        //   fontWeight="bold"
        >{bar.data.data.waitTime + ' mins'}</text></g>
  ))}
  {bars.map((bar, idx) => (
    <rect
      key={bar.key}
      x={bar.x}
      y={0}
      height={innerHeight}
      width={bar.width}
      fill="transparent"
      onMouseEnter={(e) => renderTip(e, idx, bar.x, bar.y)}
      onMouseMove={(e) => renderTip(e, idx, bar.x, bar.y)}
      onMouseLeave={tip.hideTooltip}
    />
  ))}
</>): null;
};

const DepartmentWaitTime: React.FC = () => {
  return (
    <div className="h-[400px] w-full  mx-auto">
      <ResponsiveBar
        data={barData}
        keys={['count']}
        indexBy="i"
        margin={{ top: 40, right: 80, bottom: 50, left: 60 }}
        padding={0.6}
        borderRadius={25}
        colors={d =>  [
              '#4e79a7', '#e15759', '#76b7b2',
              '#59a14f', '#edc948', '#b07aa1', '#ff9da7',
              '#9c755f', '#bab0ab'
            ][d.index]}
        axisBottom={null}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5, 
          tickRotation: 0,
          legend: 'Visitor Count',
          legendPosition: 'middle',
          legendOffset: -50,
        }}
        enableGridX={false}
        enableGridY={true}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor="#fff"
        animate={true}
        theme={theme}
        layers={[
          'grid',
          'axes',
          'bars',
          LineLayer, // Add the custom layer here
          'markers',
          'legends',
        ]}
      />
    </div>
  );
};

export default DepartmentWaitTime;
