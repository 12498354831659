import React from 'react';
import { FaSort, FaFilter, FaPlus, FaCircle } from 'react-icons/fa';
import { Camera } from './CamerasTab';



const generateColorFromString = (str: string): string => {
    // Create a hash from the string
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    // Convert the hash to a 6-digit hexadecimal color with adjustments for brightness
    let color = '#';
    for (let i = 0; i < 3; i++) {
      // Extract parts of the hash for each color component
      const value = (hash >> (i * 8)) & 0xFF;
      // Adjust each color component to be within the range 128 to 255 to ensure brightness
      const brightValue = Math.floor((value % 128) + 128).toString(16);
      color += ('00' + brightValue).substr(-2);
    }
  
    return color;
  };

  type CameraTableProps = {
    cameras: Camera[];
  };
  
  // Define the CameraTable component
  const CameraTable: React.FC<CameraTableProps> = ({ cameras }) => {
  const addCamera = () => {
    const newCamera: Camera = {
      id: cameras.length + 1,
      channel: `Channel ${cameras.length + 1}`,
      rtspUrl: `rtsp://example.com/${cameras.length + 1}`,
      tag: `Tag ${cameras.length + 1}`,
      status: Math.random() > 0.5 ? 'connected' : 'disconnected',
    };
    // setCameras([...cameras, newCamera]);
  };

  return (
    <div className="">
      <div className="flex justify-between items-center mb-4">
        <div className="flex space-x-2">
          <button className="flex items-center px-2 py-1 border rounded-md hover:bg-gray-200">
            <FaSort className="mr-1" /> Sort
          </button>
          <button className="flex items-center px-2 py-1 border rounded-md hover:bg-gray-200">
            <FaFilter className="mr-1" /> Filter
          </button>
        </div>
        <button className="flex items-center px-4 py-2 bg-brand-blue-light text-white rounded-xl hover:bg-brand-blue-dark" onClick={addCamera}>
          <FaPlus className="mr-1" /> Add Camera
        </button>
      </div>
      <table className="min-w-full bg-white border">
        <thead>
          <tr className="w-full bg-gray-100 text-left text-gray-500">
            <th className="py-2 px-4 border-b">Camera</th>
            <th className="py-2 px-4 border-b">RTSP URL</th>
            <th className="py-2 px-4 border-b">Tag</th>
            <th className="py-2 px-4 border-b">Connection Status</th>
          </tr>
        </thead>
        <tbody>
          {cameras.map(camera => (
            <tr key={camera.id} className="border-b hover:bg-gray-50">
              <td className="py-2 px-4">{camera.channel}</td>
              <td className="py-2 px-4">{camera.rtspUrl}</td>
              <td className="py-2 px-4 " >
                <div className="py-1 px-4 text-gray-500 rounded-full w-fit text-sm" style={{ backgroundColor: generateColorFromString(camera.tag) }}>{camera.tag}</div>
                
              </td>
              <td className="py-2 px-4 flex items-center">
                <FaCircle className={`mr-2 ${camera.status === 'connected' ? 'text-green-500' : 'text-red-500'}`} />
                <span className={`${camera.status === 'connected' ? 'text-green-500' : 'text-red-500'}`}>{camera.status}</span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CameraTable;
