import React from "react"
import { FaCamera, FaExclamationTriangle } from 'react-icons/fa';


function Help() {
    return (
        <div className="p-4 mx-10 mt-20">
          <div className="border-2 border-orange-500 bg-orange-100 p-4 rounded-lg flex items-center gap-4">
            <FaExclamationTriangle className="text-orange-400 text-3xl" />
            <div>
              <h2 className="text-lg font-semibold flex items-center gap-2">
               Help coming soon
              </h2>
              <p className="text-sm text-gray-600">Stay tuned</p>
            </div>
          </div>
        </div>
      );
}

export default Help;